/* eslint-disable react/no-danger */
import * as React from 'react';
import { FC } from 'react';
import { TFunction } from 'i18next';
import { ImageGallery } from '../../../ui/imageGallery';

const SectionHero: FC<{ translation: TFunction }> = ({ translation }) => {
  return (
    <section className="hero datev-hero">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-10 ">
              <h1
                className="title hero-title title-big text-black has-text-centered"
                dangerouslySetInnerHTML={{
                  __html: translation('hero.title'),
                }}
              />
              <p className="has-text-centered hero-text">
                {translation('hero.text')}
              </p>
              <div className="columns is-centered">
                <div className="column has-text-centered buttons-container">
                  <a
                    href={translation('hero.link')}
                    target="_self"
                    className="button exclusive"
                  >
                    {translation('hero.startNowButton')}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <ImageGallery
            name="shore_website_steuerberater_01.png"
            alt="shore_website_steuerberater image"
            className="hero-image"
          />

          <div className="hero-trust">
            <ImageGallery
              name="shore_trust_seals_01_gobd_new.png"
              alt="trust icon"
              className="demo-img"
            />
            <ImageGallery
              name="shore_trust_seals_01_kassensichv_new.png"
              alt="trust icon"
              className="demo-img"
            />
            <ImageGallery
              name="shore_trust_seals_01_datev.png"
              alt="trust icon"
              className="demo-img"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export { SectionHero };
