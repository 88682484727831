import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizedPageProps, IColumnList } from '../utils/types';
import { Layout } from '../components/partials/common';
import { SEO } from '../components/seo';
import { SectionHero } from '../components/partials/datev/section-hero';
import { SectionRecommendation } from '../components/partials/datev/section-suggestion';
import { SectionSetup } from '../components/partials/datev/section-setup';
import NewRepeatingSections from '../components/sections/new-repeating-section';
import { SectionTimesaver } from '../components/partials/datev/section-timesaver';
import { SectionDemo } from '../components/partials/datev/section-demo';

const ReferralPage: FC<LocalizedPageProps> = ({ pageContext }) => {
  const localKey = `${pageContext.key}`;
  const { lang, alternateUrls } = pageContext;
  const { t } = useTranslation(localKey);

  const sections: IColumnList = t('sections', {
    returnObjects: true,
  });

  return (
    <Layout pageContext={pageContext}>
      <SEO
        lang={lang}
        title={t('meta.title')}
        description={t('meta.description')}
        alternateUrls={alternateUrls}
      />
      <SectionHero translation={t} />
      <SectionRecommendation translation={t} />
      <SectionSetup translation={t} />
      <NewRepeatingSections data={sections} />
      <SectionTimesaver translation={t} />
      <SectionDemo translation={t} />
    </Layout>
  );
};

export default ReferralPage;
