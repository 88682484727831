import * as React from 'react';
import { FC } from 'react';
import { TFunction } from 'i18next';
import HubspotForm from 'react-hubspot-form';

const SectionDemo: FC<{ translation: TFunction }> = ({ translation }) => {
  return (
    <section className="section section-conversion" id="sales">
      <div className="container">
        <div className="columns is-centered position-static">
          <div className="column is-8 ">
            <h2 className="title title-medium has-text-centered has-text-black">
              {translation('formSection.title')}
            </h2>
          </div>
        </div>
        <div className="columns is-desktop is-centered position-relative">
          <div className="column is-half-desktop is-centered has-background-white demo-box">
            <div className="hbspt-form-wrapper">
              <div className="hb-target hbspt-form" id="hbspt-form-id">
                <HubspotForm
                  portalId={translation('formSection.hsForm.portalId')}
                  formId={translation('formSection.hsForm.formId')}
                  loading={<div>Loading...</div>}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { SectionDemo };
