import * as React from 'react';
import { FC } from 'react';
import { InnerSectionNew } from '../partials/common/elements/InnerSectionNew';

const NewRepeatingSections: FC<{
  data: any;
}> = ({ data }) => {
  return (
    <>
      {Object.keys(data).map((key) => {
        const { classes } = data[key];
        return (
          <section key={key} className={`${classes} section repeating-section`}>
            <InnerSectionNew key={key} data={data[key]} />
          </section>
        );
      })}
    </>
  );
};

export default NewRepeatingSections;
