import * as React from 'react';
import { FC } from 'react';
import { TFunction } from 'i18next';
import { ImageGallery } from '../../../ui/imageGallery';

const SectionSetup: FC<{ translation: TFunction }> = ({ translation }) => {
  return (
    <section className="section section-setup">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-centered  vertical-align">
            <p className="small-intro is-uppercase has-text-centered has-text-black text-medium">
              {translation('fastSetup.small')}
            </p>
            <h4 className="setup-title title has-text-centered title-medium  has-text-black ">
              {translation('fastSetup.title')}
            </h4>
            <p className="setup-text has-text-black has-text-centered text-medium">
              {translation('fastSetup.text')}
            </p>
            <ImageGallery
              name={translation('fastSetup.image')}
              alt="image issustration"
              className="setup-img bottom-image "
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export { SectionSetup };
