import * as React from 'react';
import { FC } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Image } from '../../../ui/image';
import { ImageGallery } from '../../../ui/imageGallery';
import Widgets from '../../widgets/DemoWidgets';
import { MainSlider, FullWidthSlider, FullWidthSliderPotrait } from './Slider';
import Button from '../../../ui/button';
import pages from '../../../../utils/pages';

const { getPageUrl } = pages;
const InnerSectionCentered: FC<{
  data: any;
}> = ({ data }) => {
  const {
    badge,
    badgeClass,
    title,
    text,
    mediaType,
    media,
    alt,
    ctaText,
    locale,
    key,
  } = data;
  const { t } = useTranslation('general');
  const page = getPageUrl(key, t('key'));
  return (
    <>
      <div className="container media-box-centered">
        <div className="columns is-centered is-vcentered text-row">
          <div className="column is-8 is-12-mobile has-text-centered no-pad">
            {badge && (
              <span className={`${badgeClass} is-hidden-touch`}>{badge}</span>
            )}
            <h2 className="title title-medium section-title text-black">
              {title}
            </h2>
            {Object.keys(text).map((key) => {
              return (
                <p key={`${key}-text`} className="text-medium">
                  {text[key]}
                </p>
              );
            })}
            {page && (
              <Button color="exclusive" to={page.url}>
                {t('learnMore')}
              </Button>
            )}
          </div>
        </div>
        <div className="columns is-centered is-vcentered image-row">
          <div className="column is-11 has-text-centered">
            {badge && (
              <span
                className={`${badgeClass} is-visible-mobile is-hidden-desktop`}
              >
                {badge}
              </span>
            )}
            {mediaType === 'image' && (
              <ImageGallery
                name={media}
                alt={alt}
                className="section-image image-centered "
                maxWidth="1058"
                height="458"
              />
            )}

            {mediaType === 'widget' && (
              <Widgets name={media} locale={locale || 'de-De'} />
            )}
            {mediaType === 'slider' && data.mobileMedia && (
              <>
                <MainSlider
                  slides={data.mobileMedia}
                  classes="section-slider calendar is-hidden-desktop calendar-slider"
                />
                <MainSlider
                  slides={media}
                  classes="section-slider calendar is-hidden-touch "
                />
              </>
            )}
          </div>
        </div>
      </div>
      {mediaType === 'slider2' && (
        <FullWidthSlider slides={media} classes="section-slider " />
      )}
      {mediaType === 'slider3' && (
        <FullWidthSliderPotrait slides={media} classes="section-slider " />
      )}
    </>
  );
};

const InnerSectionRTL: FC<{
  data: any;
}> = ({ data }) => {
  const {
    badge,
    badgeClass,
    title,
    text,
    mediaType,
    key,
    ctaUrl,
    media,
    alt,
    locale,
  } = data;
  const { t } = useTranslation('general');
  const page = getPageUrl(key, t('key'));
  return (
    <div className="container media-box-rtl has-media-left">
      <div className="columns is-centered is-vcentered">
        <div className="column is-4 is-12-mobile">
          {badge && (
            <span
              className={`${badgeClass} is-visible-mobile is-hidden-desktop`}
            >
              {badge}
            </span>
          )}
          {mediaType === 'image' && (
            <ImageGallery
              name={media}
              alt={alt}
              className="section-image image-left"
              maxWidth="1058"
              height="384"
            />
          )}
          {mediaType === 'widget' && (
            <Widgets name={media} locale={locale || 'de-De'} />
          )}
          {mediaType === 'slider' && data.mobileMedia && (
            <>
              <MainSlider
                slides={data.mobileMedia}
                classes="section-slider section-slider-left mobile-slider is-hidden-desktop calendar-slider"
              />
              <MainSlider
                slides={media}
                classes="section-slider section-slider-left calendar is-hidden-touch "
              />
            </>
          )}
        </div>

        <div className="column is-1 is-hidden-mobile " />
        <div className="column is-5-tablet is-4-desktop right-col is-12-mobile no-pad">
          {badge && (
            <span className={`${badgeClass} is-hidden-touch`}> {badge} </span>
          )}
          <h2 className="title title-medium section-title text-black">
            {title}
          </h2>
          {Object.keys(text).map((key) => {
            return (
              <p key={`${key}-text`} className="text-medium">
                {text[key]}
              </p>
            );
          })}
          {page && (
            <Button to={page.url} link className="inline">
              {t('learnMore')}
              <Image
                name="angle-right.svg"
                alt="angle-left icon"
                className="inline-icon angle inline"
              />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const InnerSectionLTR: FC<{
  data: any;
}> = ({ data }) => {
  const {
    badge,
    badgeClass,
    title,
    text,
    mediaType,
    media,
    button,
    link,
    key,
    locale,
    alt,
  } = data;
  const { t } = useTranslation('general');

  return (
    <div className="container media-box-rtl">
      <div className="columns is-centered is-vcentered">
        <div className="column is-5 left-col is-12-mobile no-pad ">
          <div className="innerwrap">
            {badge && (
              <span className={`${badgeClass} is-hidden-touch`}>{badge}</span>
            )}
            <h2 className="title title-medium section-title text-black">
              {title}
            </h2>
            {Object.keys(text).map((key) => {
              return (
                <p key={`${key}-text`} className="text-medium">
                  {text[key]}
                </p>
              );
            })}
            {key && (
              <Button
                to={
                  getPageUrl(key, t('key')) ? getPageUrl(key, t('key')).url : ''
                }
                link
                className="inline"
              >
                {t('learnMore')}
                <Image
                  name="angle-right.svg"
                  alt="angle-left icon"
                  className="inline-icon angle inline"
                />
              </Button>
            )}
          </div>
        </div>
        <div className="column is-1 is-hidden-mobile" />
        <div className="column is-4 is-12-mobile">
          {badge && (
            <span
              className={`${badgeClass} is-visible-mobile is-hidden-desktop`}
            >
              {badge}
            </span>
          )}
          {mediaType === 'widget' && (
            <Widgets name={media} locale={locale || 'de-De'} />
          )}
          {mediaType === 'image' && (
            <ImageGallery
              name={media}
              alt={alt}
              className="section-image image-right"
              maxWidth="1058"
              height="384"
            />
          )}
          {mediaType === 'slider' && data.mobileMedia && (
            <>
              <MainSlider
                slides={data.mobileMedia}
                classes="section-slider section-slider-right mobile-skider is-hidden-desktop calendar-slider"
              />
              <MainSlider
                slides={media}
                classes="section-slider section-slider-right calendar is-hidden-touch "
              />
            </>
          )}
        </div>
      </div>
      {button && (
        <div className="columns is-centered">
          <div className="column has-text-centered buttons-container">
            <a
              href={link}
              target="_self"
              className="cta button medium exclusive"
            >
              {button}
            </a>
          </div>
        </div>
      )}
    </div>
  );
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore-start

// tslint:disable-next-line
const InnerSectionNew: FC<{ data: any }> = ({ data }) => {
  const { boxType } = data;
  if (boxType === 'ltr') return <InnerSectionLTR data={data} />;
  if (boxType === 'rtl')
    return isMobileOnly ? (
      <InnerSectionLTR data={data} />
    ) : (
      <InnerSectionRTL data={data} />
    );
  if (boxType === 'centered') return <InnerSectionCentered data={data} />;
  return '';
};

export {
  InnerSectionNew,
  InnerSectionCentered,
  InnerSectionRTL,
  InnerSectionLTR,
};
