import * as React from 'react';
import { FC } from 'react';
import { TFunction } from 'i18next';
import { Image } from '../../../ui/image';

const SectionTimesaver: FC<{ translation: TFunction }> = ({ translation }) => {
  const columns = translation('timeSaver.list', {
    returnObjects: true,
  });

  return (
    <section className="section section-timesaver has-background-grey">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-8 is-12-mobile">
            <h2 className="title title-large has-text-centered text-black">
              {translation('timeSaver.title')}
            </h2>
            <p className="timesaver-text has-text-centered ">
              {translation('timeSaver.text')}
            </p>
          </div>
        </div>
        <div className="columns is-centered">
          {Object.keys(columns).map((key) => {
            const { icon, text, alt } = columns[key];
            return (
              <div key={key} className="column has-text-centered">
                <Image name={icon} alt={alt || ''} className="column-icon" />
                <p className="has-text-centered text-medium">{text}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export { SectionTimesaver };
