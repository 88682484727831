/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import { FC } from 'react';
/* 
hair-santa-monica
shore-madrid/services?locale=es
shore-munchen/services?locale=de
shore-santa-monica/services?locale=en
*/
const Booking: FC<{ locale: string }> = ({ locale }) => {
  const getStore = (lang: string) => {
    switch (lang) {
      case 'de':
        return 'shore-munchen';
      case 'de-DE':
        return 'shore-munchen';
      case 'en-US':
        return 'shore-santa-monica';
      case 'us':
        return 'shore-santa-monica';
      case 'es-ES':
        return 'shore-madrid';
      case 'fr':
        return 'flag-france.png';
      default:
        return 'shore-santa-monica';
    }
  };
  const store = getStore(locale);

  return (
    <iframe
      title="test"
      className="shore-booking-iframe"
      src={`https://assets-cdn.shore.com/booking/master/current/index.html?locale=${locale}&origin=standalone#/${store}/services`}
      width="350"
      height="420"
      frameBorder="0"
    >
      {' '}
    </iframe>
  );
};
// @ts-ignore
const Widgets: FC<{ name?: string; locale?: any }> = ({ name, locale }) => {
  if (name === 'booking') return <Booking locale={locale} />;

  return '';
};

Widgets.defaultProps = {
  name: 'booking',
  locale: 'de-DE',
};
export default Widgets;
