import * as React from 'react';
import { FC } from 'react';
import { TFunction } from 'i18next';

const SectionRecommendation: FC<{ translation: TFunction }> = ({
  translation,
}) => {
  return (
    <section className="section section-recommend">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-5 ">
            <h2 className="title recommend-title title-medium text-black has-text-left">
              {translation('sectionRecommend.title')}
            </h2>
            <p className="recommend-text has-text-left text-medium">
              {translation('sectionRecommend.text')}
            </p>
          </div>
          <div className="column is-5 ">
            <div className="card recommend-card">
              <h2 className="title recommend-card-title text-white has-text-centered">
                {translation('sectionRecommend.cardTextOne')}
                <span>{translation('sectionRecommend.cardSmall')}</span>
                {translation('sectionRecommend.cardTextTwo')}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { SectionRecommendation };
